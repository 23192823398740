.font-flex {
  width: 100%;
  display: flex;

  .font-left {
    flex: 1 1;
    padding-right: 6px;
  }

  .font-right {
    flex: 1 1;
    padding-left: 6px;

    &.text-right {
      justify-content: right;
      text-align: right;
    }
  }
}
