@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('../../../../../node_modules/jquery-ui/themes/base/all.css');
@import url('../../../../../node_modules/devextreme/dist/css/dx.light.css');
@import url('../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css');
@import url('../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css');
@import url('../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css');
@import url('../../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css');
@import url('../../../../../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css');
@import './treeview.scss';
@import './list.scss';
@import './typography.scss';
@import './common/color.scss';
@import './font.scss';
@import './shareList.scss';

// .hide .dx-widget.dx-collection {
//   display: none;
// }

.sub-header-box-container {
  display: flex;
  width: 100%;
  line-height: 24px;
}

.MuiList-root {
  .MuiListItemIcon-root {
    min-width: unset;
    width: 32px;
    height: 32px;
    font-size: 32px;
  }
}

.container-card-detail {
  width: 100%;
  margin-bottom: 14px;
  box-sizing: border-box;

  .label-f {
    font: 300 10px/16px Poppins, sans-serif;
    color: #878787;
  }

  .value-f-primary {
    font: 400 12px/16px Poppins, sans-serif;
    color: $primary-color;
  }

  .value-f-black {
    font: 400 12px/16px Poppins, sans-serif;
    color: $color-black;
  }
}

.text-xsTitle {
  font: 600 12px/16px Poppins, sans-serif !important;
  color: $primary-color;
}

.text-xsTitle-black {
  font: 600 12px/16px Poppins, sans-serif !important;
  color: $color-black;
}

.container-form {
  .upload-avatar {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 6px;
  }
}

.switch-entity-dialog {
  .MuiDialogContent-root {
    padding: 0px 0px 12px 0px !important;
  }

  .MuiList-padding {
    padding: 0px !important;
  }

  .MuiListItem-root {
    margin: 0px;
    border-bottom: 1px solid #ebebeb;
    box-shadow: none;
    border-radius: 0px;
  }
  .MuiListItem-root:last-child {
    border-bottom: none;
  }
}

.display-none {
  display: none;
}

.custom-search-filter-box {
  width: 100%;
  display: flex;

  .search-box {
    width: 75%;
    padding-right: 6px;
  }
  .filter-box {
    width: 25%;
    padding-left: 6px;
  }
}

.custom-container {
  margin-top: unset !important;
}

.MuiTooltip-tooltip {
  background-color: rgb(255, 255, 255) !important;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  font-size: 11px !important;
}
